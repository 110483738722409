
/* GLOBAL CLASSES */

    :root {
      --purple-color: rgb(46,22,91);
      --light-blue: rgb(237,241,246);
    }


    /* TEXT DECORATION */

    .uppercase {
      text-transform: uppercase;
    }

    .text-none {
      text-decoration: none;
    }

    .capitalize {
      text-transform: capitalize;
    }

    .text-center {
      text-align: center;
    }

    .text-end {
      text-align: end;
    }

    .text-right {
      text-align: right;
    }

    .italics {
      font-style: italic;
    }

    /* BUTTON */

    .button {
      cursor: pointer;
      width: 100%;
      align-items: center;
      padding: 1em 0 1em 0;
      font-weight: 600;
    }

    .chart {
      top: 12%;
      right: 0;
      left: 96%;
      position: fixed;
      z-index: 1;
      width: 100%;
    }

    .profile {
      border-radius: 100%;
      background-color: var(--purple-color);
      padding: 4px 8px  ;
    }

    .nav-search {
      border: 1px solid var(--purple-color);
      border-radius: 20px; 
      padding: 4px 10px;
      height: 40px;
    }

    select, option {
      border: 1px solid var(--purple-color);
      border-radius: 3px;
    }

    option:hover {
      background-color: red;
    }

    select:focus {
      border-color: var(--purple-color);
      color: var(--purple-color);
    }

    
.inputStyle {
  /* width: 50% !important; */
  height: 45px;
  width: 40px !important;
  border-radius: 7px;
  border: 1px solid rgba(128, 128, 128, 0.801);
  margin-left: 8px;
  margin-right: 8px;
  background: #fff;
  font-size: 20px;
}
.p3 {
  font-size: 14px;
}
.resend {
  text-decoration: underline;
  font-size: 14px;
}
.otpElements {
  display: flex;
  flex-direction: column;
  align-items: baseline;
  margin-top: 20px;
  margin-bottom: 50px;
  padding-left: 18px;
  justify-content: center;
}


    /* COLORS */

    .bg-grey {
      background-color: rgb(230,229,230);
    }

    .bg-spot {
      background-color: rgb(195,207,226);
    }

    .grey {
      color: rgb(143, 143, 143);
    }
    
    .purple {
      color: var(--purple-color);
    }

    .like-brown {
      color: rgb(255,144,100);
    }
    
    .light-blue {
      background-color: var(--light-blue);
    }

    .history-color {
      background-color: rgb(200,195,206);
    }
    
    .txt-light-blue {
      color: var(--light-blue);
    }

    .bg-purple {
      background-color: var(--purple-color);
    }
    .bg-red {
      background-color: red;
    }

    .bg-purple-grad {
      /* background-color: ; */
      background-image: linear-gradient(to right, rgb(17,0,34), rgba(39, 17, 77));
    }

    .white {
      color: #fff;
    }

    .red {
      color: #ff0000;
    }
    
    .green {
      color: green;
    }

    .bg-white {
      background-color: #fff;
    }

    .black {
      color: #000;
    }

    .bg-black {
      background-color: #000;
    }


    /* SPACES */

    .p-1 {
      padding: 1em;
    }

    .pl-1 {
      padding-left: 1em;
    }

    .pl-2 {
      padding-left: 2em;
    }

    .pl-4 {
      padding-left: 4em;
    }

    .pl-6 {
      padding-left: 6em;
    }

    .pr-1 {
      padding-right: 1em;
    }

    .pr-2 {
      padding-right: 2em;
    }

    .pr-4 {
      padding-right: 4em;
    }

    .pr-6 {
      padding-right: 6em;
    }

    .pt-05 {
      padding-top: .5em;
    }

    .pt-1 {
      padding-top: 1em;
    }

    .pt-2 {
      padding-top: 2em;
    }

    .pt-4 {
      padding-top: 4em;
    }

    .pt-4-5 {
      padding-top: 4.5em;
    }

    .pt-5 {
      padding-top: 5em;
    }

    .pt-6 {
      padding-top: 6em;
    }

    .pt-8 {
      padding-top: 8em;
    }

    .pb-05 {
      padding-bottom: .5em;
    }

    .pb-1 {
      padding-bottom: 1em;
    }

    .pb-2 {
      padding-bottom: 2em;
    }

    .pb-4 {
      padding-bottom: 4em;
    }

    .pb-6 {
      padding-bottom: 6em;
    }

    .pb-8 {
      padding-bottom: 8em;
    } 
    .pt--2 {
      margin-top: -1em;
    }

    .mb-1 {
      margin-bottom: 1em;
    }

    .mb-2 {
      margin-bottom: 2em;
    }

    .mb-4 {
      margin-bottom: 4em;
    }

    .mb-6 {
      margin-bottom: 6em;
    }

    .mb-8 {
      margin-bottom: 8em;
    } 

    .mt-1 {
      margin-top: 1em;
    }

    .mt-2 {
      margin-top: 2em;
    }

    .mt-6 {
      margin-top: 6em;
    }

    .mt-8 {
      margin-top: 8em;
    } 

    .ml-1 {
      margin-left: 1em;
    }

    .ml-2 {
      margin-left: 2em;
    }

    .ml-4 {
      margin-left: 4em;
    }

    .ml-5 {
      margin-left: 5em;
    }

    .ml-6 {
      margin-left: 6em;
    }

    .ml-8 {
      margin-left: 8em;
    } 

    .ml-10 {
      margin-left: 10em;
    } 

    .mr-1 {
      margin-right: 1em;
    }

    .mr-2 {
      margin-right: 2em;
    }

    .mr-4 {
      margin-right: 4em;
    }

    .mr-6 {
      margin-right: 6em;
    }

    .mr-8 {
      margin-right: 8em;
    } 

    

    /* FLEX */

    .table {
      display: table;
      /* width: 100%; */
      overflow: scroll;
    }

    .wrapper {
      overflow-x:  scroll;
      width: 99%;
    }

    .row {
      display: table-row;
   }
   
   .cell {
      display: table-cell;
      overflow: scroll;
   }

   .table-cell {
    display: table-cell;
   }
   
   .row,
   .cell {
     padding: 10px;
   }

    .h-fit {
      height: auto;
    }

    .h-10vh {
      height: 10vh;
    }

    .h-20vh {
      height: 20vh;
    }

    .h-35vh {
      height: 35vh;
    }

    .h-40vh {
      height: 40vh;
    }

    .h-50vh {
      height: 50vh;
    }

    .h-70vh {
      height: 70vh;
    }

    .h-80vh {
      height: 80vh;
    }

    .h-90vh {
      height: 90vh;
    }

    .h-100vh {
      height: 100vh;
    }
    
    .flex-0-auto {
      flex: 0 50%;
    }
    
    .flex-auto {
      flex: 0 auto;
    }
    
    .column {
      flex-flow: column;
    }
    
    .j-center {
      justify-content: center;
    }

    .ji-center {
      justify-items: center;
    }

    .j-sb {
      justify-content: space-between;
    }

    .j-even {
      justify-content: space-evenly;
    }

    .j-around {
      justify-content: space-around;
    }

    .j-end {
      justify-content: end;
    }

    .j-start {
      justify-content: start;
    }

    .align-top {
      align-items: flex-start;
    }

    .align-center {
      align-items: center;
    }

    .align-end {
      align-items: flex-end;
    }

    .relative {
      position: relative;
    }

    .absolute {
      position: absolute;
    }

    .fixed {
      position: fixed;
    }

    .top-0 {
      top: 0;
    }

    .left-0 {
      left: 0;
    }
    

    .right-1 {
      right: 1%;
    }
    .right-4 {
      right: 4%;
    }
    .right-10 {
      right: 10%;
    }
 

    .top-10 {
      top: 4em;
    }

    .top-20 {
      top: 10em;
    }

    .tp-input {
      width: 70px;
      border: 0;
      border: 1px solid;
    }
    .log-input {
      width: 100%;
      border: 0;
      border-bottom: 1px solid;
    }

    .log-input:active {
      width: 100%;
      border: 0;
      border-bottom: 1px solid; 
    }


    /* BORDER */

    .border-0 {
      border: 0;
    }

    .border-1 {
      border: 1px solid ;
    }

    .border-1-purple {
      border: 1px solid var(--purple-color);
    }

    .border-radius-5 {
      border-radius: 5px;
    }

    .border-radius-10 {
      border-radius: 10px;
    }

    .border-radius-40 {
      border-radius: 40px;
    }

    .border-radius-30 {
      border-radius: 30px;
    }


    /*  */

    #Editable-line {
      width: 70%;
    }

    #Layer_1 {
      width: 50%;
    }

    #XMLID_1_, #XMLID_2_ {
      /* fill: aqua; */
      stroke: var(--purple-color);
    }




    /* FONTS */

    .lh-1 {
      line-height: 1em;
    }

    .fw-100 {
      font-weight: 100;
    }

    .fw-200 {
      font-weight: 200;
    }

    .pointer {
      cursor: pointer;
    }

    .fw-500 {
      font-weight: 400;
    }

    .fw-400 {
      font-weight: 400;
    }

    .fw-600 {
      font-weight: 600;
    }

    .fw-700 {
      font-weight: 700;
    }

    .fw-900 {
      font-weight: 900;
    }

    
    .f-10 {
      font-size: 10px;
    }
    
    .f-12 {
      font-size: 12px;
    }
    
    .f-14 {
      font-size: 14px;
    }
    
    .f-16 {
      font-size: 16px;
    }
    
    .f-18 {
      font-size: 18px;
    }

    .f-20 {
      font-size: 20px;
    }

    .f-24 {
      font-size: 24px;
    }

    .f-28 {
      font-size: 28px;
    }

    .f-38 {
      font-size: 38px;
    }

    .f-40 {
      font-size: 40px;
    }

    .f-100 {
      font-size: 100px;
    }
 
    .icon-box {
      filter: drop-shadow(4px 4px 2px #ccc);
      background-color: #fff;
      padding: 5px;
      height: 55px;
      width: 55px;
      border-radius: 10px;
      text-align: center; 
      border: 1px solid var(--purple-color);
      z-index: -1;
      position: relative;
      font-size: 12px;
      cursor: pointer;
    }
    .icon-box:hover {
      /* filter: drop-shadow(4px 4px 2px #ccc); */
      background-color: var(--purple-color);
      color: #000;
      padding: 5px;
      height: 45px;
      width: 45px;
      border-radius: 10px;
      text-align: center; 
      border: 1px solid var(--purple-color);
      z-index: -1;
      position: relative;
      font-size: 12px;
      cursor: pointer;
    }
    

    .dropshadow {
      filter: drop-shadow(4px 4px 2px #ccc);
    }

    .link {
      text-decoration: none;
      color: var(--purple-color);
    }
    .link:hover {
      text-decoration: none;
      /* color: var(--purple-color); */
    }

    .icon-box>img {
      width: 45%;
      padding: 0;
    }
 
    .icon-box-text {
      filter: drop-shadow(4px 4px 2px #ccc);
      background-color: #fff;
      padding: 5px;
      height: 50px;
      width: 200px;
      border-radius: 10px;
      text-align: center;
      display: flex;
      justify-content: center;
      align-items: center;
      border: 1px solid var(--purple-color);
      z-index: -1;
      position: relative;
    }
    .icon-box-text-add {
      filter: drop-shadow(4px 4px 2px #ccc);
      background-color: #fff;
      padding: 5px;
      height: 40px;
      width: 200px;      
      text-align: center;
      display: flex;
      justify-content: center;
      align-items: center;
      border: 1px solid var(--purple-color);
      z-index: -1;
      position: relative;
    }

    .gain {
      color: rgb(1, 2216, 1);
      padding: 2px 10px; 
      border: 1px solid rgb(1, 216, 1);
      border-radius: 50%;
    }

    .loss {
      margin-left: .5em;
      color: #ccc;
      padding: 2px 10px; 
      border: 1px solid #ccc;
      border-radius: 50%;
    }

    ::-webkit-scrollbar {
      height: 4px;              /* height of horizontal scrollbar ← You're missing this */
       width: 0px;               /* width of vertical scrollbar */
      /* border: 1px solid #fff; */
      
    }

    ::-webkit-scrollbar-track {
      border-radius: 0;
      background: #eeeeee;
    }
    
    ::-webkit-scrollbar-thumb {
      border-radius: 0;
      background: #ccc;
    }


    
#navbar {
  position: fixed;
  min-width: 5em;
  top: 4.5em;
  left: 0;
  width: 6em;
  /* z-index: -1; */
  /* height: 170px;  */
}

nav a {
  text-align: center;
  filter: drop-shadow(4px 4px 2px #ccc);
  color: var(--purple-color);
  padding:  5px;
  margin-bottom: 1em;
  background-color: #fff;
  display:  block;
  stroke: #ccc;
  height: 65px;
  width: 65px;
  border-radius: 10px;
  text-align: center; 
  border: 1px solid var(--purple-color);
  z-index: -1;
  position: relative;
  font-size: 12px;
  cursor: pointer;
}

 
 nav a.active {
  filter: drop-shadow(4px 4px 2px #ccc);
  background-color: var(--purple-color);
  padding:  5px;
  color: #fff;
  display: block;
  /* height: 155px; */
  stroke: #ccc;
  /* width: 55px; */
  border-radius: 10px;
  text-align: center; 
  border: 1px solid var(--purple-color);
  z-index: -1;
  position: relative;
  font-size: 12px;
  cursor: pointer;
}

nav>a.active #XMLID_1_, nav>a.active #XMLID_2_ {
  stroke: #eeeeee;
}
nav>a.active #Layer_1 {
  fill: #fff;
 }

.main-doc { 
  margin-left: 80px; 
  margin-top: 2em;
  padding: 20px; 
  z-index: -1;
}

.modal-btn {
  z-index: -1;
  position: fixed;
}

.z-1 {
  z-index: 1;
}

.z--1 {
  z-index: -1;
}

.center {
  text-align: center;
}


/* POP UP */

.modal {
  font-size: 12px;
  background-color: #ffffffb6;
  position: fixed;
  top: 0;
  bottom: 0;
  left: 0;
  height: 100%;
  width: 100%;
  /* z-index: 1; */
}

.loading {
  font-size: 12px;
  background-color: #ffffffb6;
  position: fixed;
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
  height: 100%;
  width: 100%;
  /* z-index: 1; */
}

.profile-modal {
  font-size: 12px;
  background-color: #ffffffb6;
  position: fixed;
  top: 5em;
  /* bottom: 0; */
  right: 5em;
  /* height: 100%; */
  /* width: 50%; */
  /* z-index: 1; */
}

.profile-modal-cont {
  background: #fff;
  height: 50vh;
  padding: 10px 30px;
}

.modal-contents {
  margin: 0;
  padding: 0;
}

.h-50 {
  height: 50%;
  
}

.h-sm-vh {
  height: 100vh;
  
}

.group-input {
  height: 40px;
  width: 100%;
  padding: 0 13px;
}

.group-input:focus {
  border: 0px;
  /* background-color: ; */

}

.popup-btn {
  /* width: 150px; */
  border: 1px solid;
  padding: 0.8em 1em;
  border-radius: 10px;
  text-align: center;
}

input:focus {
  outline: 0;
}

  


    /* END OF GLOBAL CLASSES */




/* MOBILE CLASSES */

.mb-flex {
  display: flex;
}

.splash {
  background-image: url('./assets/sss.png');
  /* height: 100vh; */
  /* width: 100%; */
  background-blend-mode: multiply;
  background-color: #000000a1;
  background-size: cover;
  background-position: bottom right;
  background-repeat: no-repeat;
  margin: 0;
  padding: 0;
}



.mb-hidden {
  display: none;
}

.mb-fps-hidden {
  display: none;
}

.lg-hidden {
  display: block;
}


.ph-sm-4 {
  padding-left: 4em;
  padding-right: 4em;
}

/* MEDIUM */

@media screen and (min-width: 600px) {

  .mb-hidden {
    display: none;
  }

  .mm-flex {
    display: flex;
  }

  .group-input {
    height: 40px;
    /* width: 100px; */
    padding: 0 13px;
  }
  

  .mb-fps-hidden {
    display: none;
  }

  
  .lg-hidden {
    display: block;
  }

  .ph-big-15 {
    padding-left: 10em;
    padding-right: 10em;
  }

  
  .h-50 {
    height: 50%;
  }

  .h-70 {
  height: 70%;
  
}

  .w-auto {
    width: auto;
  }

  
.ph-sm-4 {
  padding-left: 8em;
  padding-right: 8em;
}
  
}

/* WEBVIEW CLASSES */


@media screen and (min-width: 900px) {
  
  .mb-hidden {
    display: block;
  }
  
  .mb-fps-hidden {
    display: none;
  }

  .group-input {
    height: 40px;
    /* width: 250px; */
    padding: 0 13px;
  }
  
  .lg-hidden {
    display: none;
  }
  .splash {
    background-image: url('./assets/sss.png');
    /* height: 100vh; */
    /* width: 100%; */
    background-blend-mode: normal;
    background-size: cover;
    background-position: center center;
    background-repeat: no-repeat;
    margin: 0;
    padding: 0;
  }

  .lg-flex {
    display: flex;
  }

  .flex-wrap {
    flex-wrap: wrap;
  }

  .grid {
    display: grid;
  }
 
  .sign-left {
    /* background-image: url('../src/assets/logo-name.svg'); */
    height: 100vh;
    width: 100%;
    background-size: 100%;
    background-position: center center;
    background-repeat: no-repeat;
    margin: 0;
    padding: 0;
  }

  .w-100 {
    width: 100%;
  }

  .w-50 {
    width: 50%;
  }

  .w-30 {
    width: 30%;
  }

  .w-60 {
    width: 60%;
  }

  .w-70 {
    width: 70%;
  }

  .w-10 {
    width: 10%;
  }

  .w-5 {
    width: 5%;
  }

  .w-15 {
    width: 15%;
  }

  .w-20 {
    width: 20%;
  }
  
  .w-25 {
    width: 25%;
  }

  .w-27 {
    width: 28%;
  }

  .w-30 {
    width: 30%;
  }

  .w-40 {
    width: 40%;
  }

  .w-90 {
    width: 90%;
  }

  .w-95 {
    width: 95%;
  }

  .w-0 {
    /* width: max-content; */
    left: 0 ;
    right: 0;
  }

  .w-max {
    width: max-content;
  }

  .h-100 {
    height: 100%;
  }

  .h-50 {
    height: 50%;
  }

  .h-70 {
    height: 70%; 
  }

  .h-60 {
    height: 60%; 
  }

  .h-40 {
    height: 40%; 
  }

  .h-30 {
    height: 30%; 
  }

  .h-20 {
    height: 20%; 
  }

  .h-10 {
    height: 10%; 
  }
  

  input.h-10 {
    height: 30px;
    width: 300pz;
  }

  /* .h-10 {
    height: 3%;
  } */

  .ph-big-10 {
    padding-left: 10em;
    padding-right: 10em;
  }

  .pv-big-10 {
    padding-top: 10em;
    padding-bottom: 10em;
  }

  .ph-big-15 {
    padding-left: 10em;
    padding-right: 10em;
  }
  /* 
  .pv-big-10 {
    padding-bottom: 10em ;
    padding-top: 10em ;
  } */
}

/* XL VIEW */

@media screen and (min-width: 1200px) {
  
  
  
    .ph-big-15 {
      padding-left: 15em;
      padding-right: 15em;
    }

    .group-input {
      height: 40px;
      /* width: 400px; */
      padding: 0 13px;
    }
}

.parent {
  height: 400px !important;
  overflow: 'hidden';
}
.chartContainer {
  width: 100%;
  height: 100px;
  max-width: 120px;
}